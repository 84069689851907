import cx from 'clsx';
import Button from 'components/button';
import Icon from 'components/icon';
import useIsMobile from 'hooks/use-is-mobile';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { Icons } from 'v2.api/src/common-generic';

import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  DrawerFooter,
} from './drawer';

export interface Props {
  title?: string | React.ReactNode;
  body: React.ReactNode;
  isVisible: boolean;
  onCancel?: () => void;
  destroy?: () => void;
  iconType?: Icons;
  destroyOnAction?: boolean;
  contentClassName?: string;
  shouldCloseOnClickOutside?: boolean;
  shouldRenderCloseButton?: boolean;
  titleClassName?: string;
  hasPadding?: boolean;
  renderFooter?: (
    onAction: (onAction: () => Promise<void> | void) => () => Promise<void>,
    onClose: () => void,
    isLoading: boolean,
  ) => React.ReactNode;
}

const Modal = ({
  onCancel,
  destroy,
  title,
  body,
  isVisible,
  contentClassName,
  destroyOnAction = true,
  hasPadding = true,
  renderFooter,
  iconType,
  shouldCloseOnClickOutside = true,
  shouldRenderCloseButton = false,
  titleClassName = 'text-lg leading-6 font-medium text-primary',
}: Props): React.ReactNode => {
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useIsMobile();

  const handleAction = (onAction: () => Promise<void>) => async () => {
    try {
      setIsLoading(true);
      await onAction();
      setIsLoading(false);
      destroyOnAction && destroy?.();
    } catch {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onCancel?.();
    destroy?.();
  };

  if (isMobile) {
    return (
      <Drawer
        open={isVisible}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            handleClose();
            return;
          }
        }}
      >
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>{title}</DrawerTitle>
            <DrawerDescription>{body}</DrawerDescription>
          </DrawerHeader>
          <DrawerFooter>
            {renderFooter?.(handleAction, handleClose, isLoading)}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }

  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex min-h-screen items-end justify-center pb-20 pt-4 text-center sm:block sm:p-0 sm:px-4">
        <div
          className="fixed inset-0"
          aria-hidden="true"
          onClick={shouldCloseOnClickOutside ? handleClose : null}
        >
          <div className="absolute inset-0 bg-surface-9 opacity-50"></div>
        </div>
        {/* This element is to trick the browser into centering the modal contents */}
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className={cx(
            'relative inline-block w-full overflow-hidden rounded-lg bg-surface-2 text-left align-bottom shadow-xl sm:my-8 sm:align-middle md:w-3/4',
            contentClassName,
          )}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className={cx('bg-surface-2', {
              'px-4 pb-4 pt-5 sm:p-6 sm:pb-4': hasPadding,
            })}
          >
            <div className="sm:flex sm:items-start sm:space-x-4">
              {iconType && (
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-surface-4 sm:mx-0 sm:h-10 sm:w-10">
                  <Icon type={iconType} size="md" className="fill-icon-3" />
                </div>
              )}
              <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                <div className="relative">
                  {title && (
                    <h3
                      className={cx(titleClassName, {
                        'text-center': !iconType,
                      })}
                      id="modal-headline"
                    >
                      {title}
                    </h3>
                  )}
                  {shouldRenderCloseButton && (
                    <Button
                      onClick={handleClose}
                      icon="cross"
                      type="styleless-icon"
                      className="absolute right-0 top-0"
                    />
                  )}
                </div>
                <div className="mt-2">{body}</div>
              </div>
            </div>
          </div>
          {renderFooter?.(handleAction, handleClose, isLoading)}
        </div>
      </div>
    </div>,
    document.getElementById('portal'),
  );
};

export default Modal;
