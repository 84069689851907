import { TooltipContext } from 'components/tooltip/tooltip-context';
import { useContext } from 'react';

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};
