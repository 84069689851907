import cx from 'clsx';
import uniqueId from 'lodash/uniqueId';
import { useRef } from 'react';

interface Props {
  fieldId?: string;
  onChange: (value: boolean) => void;
  isChecked: boolean;
  leftLabel?: string | React.ReactNode;
  label?: string | React.ReactNode;
  isDisabled?: boolean;
  switchColor?: string;
  onMouseEnter?: () => void;
}

const Switch = ({
  fieldId = '',
  isChecked = false,
  leftLabel,
  label,
  onChange,
  isDisabled = false,
  switchColor,
  onMouseEnter,
}: Props): React.ReactNode => {
  const id = useRef(uniqueId());

  return (
    <label
      id={fieldId}
      htmlFor={`switch-${id.current}`}
      className={cx(
        'inline-flex items-center',
        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
      )}
      onClick={(evt) => evt.stopPropagation()}
      onMouseEnter={onMouseEnter}
    >
      {leftLabel ? (
        <span
          className={cx('mr-3 text-xs font-semibold', {
            'text-text-3': !isChecked,
            'text-text-4': isChecked,
          })}
        >
          {leftLabel}
        </span>
      ) : (
        ''
      )}
      <span className="relative">
        <span
          className={cx(
            `block h-6 w-10 rounded-full shadow-inner ${
              isChecked ? switchColor : ''
            }`,
            {
              'bg-surface-10': isChecked,
              'bg-surface-16': !isChecked,
            },
          )}
        />
        <span
          className={cx(
            'focus-within:shadow-outline absolute inset-y-0 left-0 ml-1 mt-1 block h-4 w-4 rounded-full shadow transition-transform duration-300 ease-in-out',
            {
              'translate-x-full bg-surface-2': isChecked,
              'bg-surface-2': !isChecked,
            },
          )}
        >
          <input
            id={`switch-${id.current}`}
            name="switch"
            type="checkbox"
            role="switch"
            aria-checked={isChecked || false}
            disabled={isDisabled}
            checked={isChecked || false}
            className="absolute h-0 w-0 opacity-0"
            onChange={() => {
              onChange(!isChecked);
            }}
          />
        </span>
      </span>
      {label && (
        <span
          className={cx('ml-3 text-xs font-semibold', {
            'text-text-3': isChecked,
            'text-text-4': !isChecked,
          })}
        >
          {label}
        </span>
      )}
    </label>
  );
};

export default Switch;
