/* eslint-disable max-lines */
import { Placement } from '@floating-ui/react';
import cx from 'clsx';
import Dropdown from 'components/dropdown';
import Icon from 'components/icon';
import InputError from 'components/input-error';
import InputWrapper from 'components/input-wrapper';
import toast from 'components/toast';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import map from 'lodash/map';
import { useState, useRef, useEffect, useCallback } from 'react';
import { InputStyleTypes } from 'types/input';
import { Icons } from 'v2.api/src/common-generic';
import {
  SelectValue,
  SelectValues,
} from 'v2.api/src/common-generic/types/select';

interface Props<T> {
  fieldId?: string;
  values?: SelectValues<T, React.ReactNode>;
  icon?: Icons;
  placeholder?: string;
  placement?: Placement;
  resetLabel?: string;
  isDisabled?: boolean;
  isSearchEnabled?: boolean;
  hasResetButton?: boolean;
  selectedValues?: SelectValues<T, React.ReactNode>;
  className?: string;
  selectWrapperClassName?: string;
  dropdownTriggerClassName?: string;
  dropdownClassName?: string;
  emptySearchMessage?: string;
  isMultiple?: boolean;
  error?: React.ReactNode;
  label?: string | React.ReactNode;
  isApiSearchEnabled?: boolean;
  searchField?: string;
  onApiSearch?: (search: string) => Promise<void>;
  onChange: (selectedValue: SelectValues<T, React.ReactNode>) => void;
  renderAfterDropdown?: () => React.ReactNode;
  renderButton?: () => React.ReactNode;
  getAsyncValues?: <P>(
    search?: string,
  ) => Promise<SelectValues<P, React.ReactNode>>;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  inputWrapperType?: InputStyleTypes;
  isChangeConfirmed?: boolean;
  inputWrapperClassName?: string;
  dropdownWrapperClassname?: string;
  maxSelectedValues?: number;
  shouldDisplayAsterisk?: boolean;
  onCloseWithoutChange?: () => void;
  rowClassName?: string;
  isDropdownVisibleByDefault?: boolean;
  hasDropdownWidth?: boolean;
}

function Select<T>({
  fieldId = '',
  values,
  icon,
  placeholder,
  placement,
  resetLabel,
  isDisabled = false,
  isSearchEnabled = false,
  hasResetButton = true,
  selectedValues,
  className,
  selectWrapperClassName,
  dropdownTriggerClassName,
  dropdownClassName,
  emptySearchMessage,
  isMultiple,
  error,
  label,
  onChange,
  onFocus,
  renderButton,
  getAsyncValues,
  onMouseEnter,
  isApiSearchEnabled,
  onApiSearch,
  inputWrapperType,
  isChangeConfirmed = true,
  hasDropdownWidth = true,
  inputWrapperClassName,
  dropdownWrapperClassname,
  maxSelectedValues,
  shouldDisplayAsterisk = false,
  onCloseWithoutChange,
  rowClassName,
  isDropdownVisibleByDefault = false,
}: Props<T>) {
  const [currentValues, setCurrentValues] = useState<
    SelectValues<T, React.ReactNode>
  >([]);
  const [asyncValues, setAsyncValues] =
    useState<SelectValues<T, React.ReactNode>>();
  const [isDropdownVisible, setIsDropdownVisible] = useState(
    isDropdownVisibleByDefault,
  );
  const [isAsyncSelectLoading, setIsAsyncSelectLoading] = useState(false);
  const selectButton = useRef<HTMLDivElement>();

  const initAsyncValues = useCallback(
    async (search?: string) => {
      setIsAsyncSelectLoading(true);

      const asyncValues = await getAsyncValues<T>(search);

      setIsAsyncSelectLoading(false);
      setAsyncValues(asyncValues);
    },
    [getAsyncValues],
  );

  useEffect(() => {
    if ([!isDropdownVisible, !getAsyncValues].some(Boolean)) {
      return;
    }
    initAsyncValues();
  }, [isDropdownVisible, getAsyncValues, initAsyncValues]);

  useEffect(() => {
    isEmpty(selectedValues)
      ? setCurrentValues([])
      : setCurrentValues(selectedValues);
  }, [selectedValues]);

  const handleChange = (newSelectedValue?: SelectValue<T, React.ReactNode>) => {
    const value = newSelectedValue ? [newSelectedValue] : [];

    if (isChangeConfirmed) {
      setCurrentValues(value);
    }

    onChange?.(value);
  };

  const handleChangeMultiple = (
    newSelectedValue?: SelectValue<T, React.ReactNode>,
  ) => {
    if (!newSelectedValue) {
      setCurrentValues([]);
      return onChange?.(null);
    }

    if (find(currentValues, ({ id }) => id === newSelectedValue.id)) {
      const newCurrentValues = filter(
        currentValues,
        ({ id }) => id != newSelectedValue.id,
      );
      setCurrentValues(newCurrentValues);
      return onChange?.(newCurrentValues);
    }

    if (maxSelectedValues && currentValues.length + 1 > maxSelectedValues) {
      return toast.warning(
        `Tu ne peux sélectionner que ${maxSelectedValues} élément(s).`,
      );
    }

    setCurrentValues([...currentValues, newSelectedValue]);
    onChange?.([...currentValues, newSelectedValue]);
  };

  const handleChangeDropdownVisibility = (isVisible: boolean) => {
    if (!isVisible) onCloseWithoutChange?.();
    setIsDropdownVisible(isVisible);
  };

  const getSelectedLabel = () => {
    if (isMultiple) {
      return join(
        map(currentValues, ({ label, filterValue }) =>
          typeof label === 'string' ? label : filterValue,
        ),
        ', ',
      );
    }

    return isEmpty(selectedValues)
      ? null
      : typeof selectedValues === 'string'
        ? find(values, { id: selectedValues })?.label
        : selectedValues[0]?.label;
  };

  const renderDefaultButton = () => {
    const selectedLabel = getSelectedLabel();

    return (
      <InputWrapper
        fieldId={fieldId}
        label={label}
        onMouseEnter={() => {
          onMouseEnter?.();
        }}
        onFocus={onFocus}
        placeholder={placeholder}
        isDisabled={isDisabled}
        className={cx(className, {
          'h-10': !className && inputWrapperType !== 'filter',
          'h-8': inputWrapperType === 'filter',
          'border-border-7': !!error,
        })}
        id="select"
        wrapperRef={selectButton}
        suffix={
          <div>
            {(selectedLabel && !isDisabled && hasResetButton) ||
            isDropdownVisibleByDefault ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onFocus?.();
                  handleChangeDropdownVisibility(false);
                  isMultiple ? handleChangeMultiple() : handleChange();
                }}
              >
                <Icon
                  type="cross"
                  size="xs"
                  className="cursor-pointer fill-icon-1"
                />
              </div>
            ) : (
              <div>
                <Icon
                  type="arrow"
                  size="xs"
                  className={cx('fill-icon-1', {
                    'rotate-180': isDropdownVisible,
                  })}
                />
              </div>
            )}
          </div>
        }
        inputWrapperType={inputWrapperType}
        inputWrapperClassName={inputWrapperClassName}
        shouldDisplayAsterisk={shouldDisplayAsterisk}
      >
        <div
          role="button"
          className="w-full min-w-0"
          onClick={onFocus}
          onMouseEnter={onMouseEnter}
          tabIndex={0}
        >
          <div className="w-full">
            <div
              className={cx(
                'w-full',
                'font-bold',
                'flex items-center',
                {
                  'cursor-pointer':
                    !selectedLabel && currentValues && !currentValues[0]?.label,
                },
                'trigger',
                selectWrapperClassName,
              )}
            >
              <div className="flex w-full items-center overflow-hidden">
                <span>
                  <Icon type={icon} size="sm" className="fill-icon-1" />
                </span>
                <div
                  className={cx(
                    {
                      'text-text-3': selectedLabel,
                      'text-color-3': !selectedLabel,
                      'ml-2': icon,
                    },
                    'mr-1 truncate font-bold',
                  )}
                >
                  {selectedLabel || placeholder}
                </div>
              </div>
            </div>
          </div>
        </div>
      </InputWrapper>
    );
  };

  const selectButtonRect = selectButton.current?.getBoundingClientRect();
  const dropdownWidth = selectButtonRect?.right - selectButtonRect?.left;

  return (
    <>
      <Dropdown
        isMultiple={isMultiple}
        values={getAsyncValues ? asyncValues : values}
        isDisabled={isDisabled}
        isSearchEnabled={isSearchEnabled}
        isLoading={isAsyncSelectLoading}
        selectedValues={currentValues}
        className={dropdownClassName}
        emptySearchMessage={emptySearchMessage}
        onChange={isMultiple ? handleChangeMultiple : handleChange}
        onChangeDropdownVisibility={handleChangeDropdownVisibility}
        renderTrigger={renderButton || renderDefaultButton}
        dropdownWidth={hasDropdownWidth && dropdownWidth}
        isDropdownVisible={isDropdownVisible}
        placement={placement}
        resetLabel={resetLabel}
        triggerClassName={dropdownTriggerClassName}
        handleAsyncSearch={onApiSearch ? onApiSearch : initAsyncValues}
        isApiSearchEnabled={isApiSearchEnabled}
        onMouseEnter={onFocus}
        wrapperClassName={dropdownWrapperClassname}
        rowClassName={rowClassName}
      />
      <InputError>{error}</InputError>
    </>
  );
}

export default Select;
