import phone from 'phone';
import { Validator } from 'v2.api/src/common-generic';

const phoneNumberValidator = (value: string): string => {
  if (phone(value).isValid) return;

  return 'Le numéro de téléphone est invalide. Format : +33...';
};

const getWithDefaultValidators = (
  validators: Array<Validator>,
  type: string,
): Array<Validator> => {
  if (type === 'phone-number') {
    return [...(validators || []), phoneNumberValidator];
  }

  return validators;
};

export { phoneNumberValidator, getWithDefaultValidators };
