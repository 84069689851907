import cx from 'clsx';
import Icon from 'components/icon';
import { Sizes } from 'v2.api/src/common-generic/types/icons';

interface Props {
  className?: string;
  size?: Sizes;
}

const Spinner = ({ className, size }: Props): React.ReactNode => {
  return (
    <Icon
      type="spinner"
      size={size}
      className={cx('animate-spin', className)}
    />
  );
};

export default Spinner;
