import { Option } from 'types/option';
import { Icons } from 'v2.api/src/common-generic';

export const fileDuplicateOptions: Array<
  Option<'cancel' | 'replace' | 'keep both'> & { className: string }
> = [
  {
    id: '0',
    label: 'Conserver l’original',
    value: 'cancel',
    className: 'my-4',
  },
  {
    id: '1',
    label: 'Remplacer',
    value: 'replace',
    className: 'my-4',
  },
  {
    id: '2',
    label: 'Conserver les deux',
    value: 'keep both',
    className: 'my-4',
  },
];

export const bolderDocumentDropzoneAccept = {
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
  'application/pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
};

export const documentTypeIcon: {
  [key: string]: { icon: Icons; iconColor: string; bgColor: string };
} = {
  docx: {
    icon: 'word',
    iconColor: 'fill-icon-23',
    bgColor: 'bg-surface-35',
  },
  doc: {
    icon: 'word',
    iconColor: 'fill-icon-23',
    bgColor: 'bg-surface-35',
  },
  pdf: {
    icon: 'pdf',
    iconColor: 'fill-icon-24',
    bgColor: 'bg-surface-36',
  },
  image: {
    icon: 'image',
    iconColor: 'fill-icon-4',
    bgColor: 'bg-surface-37',
  },
  powerpoint: {
    icon: 'powerpoint',
    iconColor: 'fill-icon-25',
    bgColor: 'bg-surface-36',
  },
  excel: {
    icon: 'excel',
    iconColor: 'fill-icon-26',
    bgColor: 'bg-surface-38',
  },
  unknown: {
    icon: 'document',
    iconColor: 'fill-icon-5',
    bgColor: 'bg-surface-3',
  },
};

export enum DocumentsType {
  Pdf = 'pdf',
  Docx = 'docx',
  Doc = 'doc',
  Image = 'image',
  Powerpoint = 'powerpoint',
  Excel = 'excel',
  Unknown = 'unknown',
}
