import { useTooltip } from 'hooks/tooltip/use-tooltip';
import { ReactNode } from 'react';

import { TooltipContext } from './tooltip-context';

import { TooltipProps } from '.';

export function TooltipWrapper({
  children,
  ...options
}: { children: ReactNode } & TooltipProps) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options);

  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
}
