import cx from 'clsx';
import Icon from 'components/icon';
import toast from 'components/toast';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import { useCallback } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { Icons, Sizes } from 'v2.api/src/common-generic';
import { CreatedFile } from 'v2.api/src/common-generic/types/form';

import InputError from './input-error';

interface Props {
  label?: string;
  className?: string;
  wrapperClassName?: string;
  labelClassName?: string;
  onChange: (arg: CreatedFile[]) => void;
  maxSize?: number;
  error?: React.ReactNode;
  isVertical?: boolean;
  isMultiple?: boolean;
  accept?: Accept;
  iconType?: Icons;
  content?: React.ReactNode;
  iconSize?: Sizes;
  noClick?: boolean;
  displayAccept?: boolean;
  onDropEnd?: (isDragActive: boolean) => void;
  onFocus?: () => void;
  isRounded?: boolean;
  spacing?: string;
  iconClassName?: string;
}

const DropZone = ({
  label,
  onChange,
  className,
  wrapperClassName,
  labelClassName,
  maxSize = 20 * 1024 * 1024,
  error,
  isVertical,
  isMultiple = true,
  accept,
  iconType,
  content,
  iconSize,
  noClick = false,
  displayAccept,
  onDropEnd,
  onFocus,
  isRounded = true,
  spacing = 'space-y-5',
  iconClassName,
}: Props): React.ReactNode => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (find(acceptedFiles, (f) => f.size === 0)) {
        toast.error(
          'On dirait qu’il s’agit d’un fichier disponible sur un cloud. Télécharge-le sur ton ordinateur, avant de réessayer.',
        );
        return;
      }

      onChange(acceptedFiles);
    },
    [onChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize,
    multiple: isMultiple,
    accept,
    onDropRejected: (fileRejections) => {
      const errors = flatMap(fileRejections, (fileRejection) =>
        map(fileRejection.errors, (error) => error.code),
      );

      if (errors.includes('file-too-large')) {
        toast.error(
          `Le fichier ajouté dépasse les ${maxSize / 1024 / 1024}Mo.`,
        );
        return;
      }

      if (errors.includes('file-invalid-type')) {
        toast.error('Format de fichier non accepté.');
        return;
      }
    },
    noClick,
    noKeyboard: true,
  });

  const renderGenericContent = () => {
    const acceptList =
      displayAccept && accept
        ? map(accept, (e) => e[0].split('.')[1]).join(', ')
        : null;

    if (isDragActive && !content) {
      return (
        <Icon
          type="upload"
          className="fill-icon-4"
          size={isVertical ? 'md' : 'sm'}
        />
      );
    }

    const surface = (
      <>
        {iconType && (
          <Icon
            type={iconType}
            className={cx(
              'fill-icon-4',
              { 'mr-2': !isVertical },
              iconClassName,
            )}
            size={iconSize ? iconSize : isVertical ? 'md' : 'sm'}
          />
        )}
        <p
          className={cx(
            { 'font-bold': !!content || !isVertical },
            labelClassName,
          )}
        >
          {label}
        </p>
        {accept && displayAccept && (
          <p className="text-text-2">
            <span className="uppercase">{acceptList}</span>
            <span>{` (taille max : ${maxSize / 1024 / 1024}Mo)`}</span>
          </p>
        )}
      </>
    );

    if (!content) return surface;

    if (isDragActive && content) {
      return (
        content && (
          <div
            className={cx(
              'pointer-events-none flex flex-col items-center',
              spacing,
            )}
            onDragLeave={() => onDropEnd(false)}
          >
            {surface}
          </div>
        )
      );
    }

    return null;
  };

  return (
    <div
      className={cx(wrapperClassName, { 'relative h-full w-full': content })}
      onMouseOver={onFocus}
      {...getRootProps()}
    >
      <div
        className={cx(
          className,
          'flex w-full items-center justify-center font-medium text-text-1',
          {
            'rounded-lg': isRounded,
            'border-2 border-dashed border-border-6 bg-surface-8':
              !isDragActive && !content,
            'absolute z-10 h-full border-2 border-dashed border-border-5 bg-surface-6 opacity-85':
              isDragActive && content,
            'border-border-7': error,
            'w-full': !isVertical,
            'cursor-pointer select-none focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2':
              !noClick,
            'h-14': !content,
            'absolute h-full': content,
          },
        )}
      >
        <input id="drop-zone" {...getInputProps()} />
        {renderGenericContent()}
      </div>
      <InputError>{error}</InputError>
      {content}
    </div>
  );
};

export default DropZone;
