import find from 'lodash/find';
import { Fields } from 'v2.api/src/common-generic/types/form';

import {
  formatDate,
  getIsoDate,
  getMonthFirstDay,
  getMonthLastDay,
  isBefore,
} from './dates';

const passwordValidator = (field: string): string => {
  if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(field)) {
    return 'Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre.';
  }
};

const emailValidator = (value: string): string => {
  if (!isEmailValid(value)) {
    return 'L’email n’est pas valide.';
  }
};

const preventBolderEmail = (email: string) => {
  if (email.endsWith('@wearebold.co')) {
    return 'L’adresse email renseigné ne peut pas être celle d’un Bolder';
  }
};

const isEmailValid = (value: string): boolean => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegex.test(value);
};

function passwordConfirmationValidator<JSXElement>(targetFieldId: string) {
  return function (value: string, fields: Fields<JSXElement>): string {
    const targetField = find(fields, { id: targetFieldId });

    if (targetField.value === value) return;

    return 'Le mot de passe ne correspond pas.';
  };
}

const website = (field: string): string => {
  if (!/[^\s.]+\.[^\s.]+$/.test(field)) {
    return 'Le site doit être sous la forme [domaine].[extension] (exemple : societe.com)';
  }
};

const clickableWebsite = (field: string): string => {
  if (!/^https?:\/\//.test(field)) {
    return 'Le site doit commencer par http:// ou https:// (exemple : https://www.societe.com)';
  }
};

const length = (length: number) => (value: string) => {
  if (value.length > length) {
    return `Le texte ne doit pas dépasser ${length} caractères.`;
  }
};

const moreThan = (minNumber: number) => (value: number) => {
  if (value <= minNumber) {
    return `Le nombre doit être supérieur à ${minNumber}.`;
  }
};

const after = (fieldId: string) => (value: string, fields: Fields<unknown>) => {
  const date = find(fields, { id: fieldId }).value as string;

  if (isBefore(new Date(value), new Date(date))) {
    return `La date doit être postérieure au ${formatDate(new Date(date))}.`;
  }
};

const firstDayOfTheMonth = (rawDate: string) => {
  const date = new Date(rawDate);
  const formattedDate = getIsoDate(date);

  const firstDay = getIsoDate(getMonthFirstDay(date));

  if (firstDay !== formattedDate) {
    return 'Tu dois sélectionner le premier jour du mois.';
  }
};

const lastDayOfTheMonth = (rawDate: string) => {
  const date = new Date(rawDate);
  const formattedDate = getIsoDate(date);

  const lastDay = getIsoDate(getMonthLastDay(date));

  if (lastDay !== formattedDate) {
    return 'Tu dois séléctionner le dernier jour du mois.';
  }
};

const isAfterToday = (rawDate: string) => {
  const date = new Date(rawDate);
  const today = new Date();

  if (isBefore(date, today)) {
    return 'Tu dois sélectionner une date postérieure à aujourd’hui.';
  }
};

export {
  passwordValidator,
  website,
  passwordConfirmationValidator,
  emailValidator,
  isEmailValid,
  clickableWebsite,
  length,
  firstDayOfTheMonth,
  lastDayOfTheMonth,
  moreThan,
  after,
  isAfterToday,
  preventBolderEmail,
};
