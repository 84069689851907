import cx from 'clsx';
import uniqueId from 'lodash/uniqueId';
import toast, { Toast, ToastOptions } from 'react-hot-toast';
import { Icons } from 'v2.api/src/common-generic';

import Button from './button';
import Icon from './icon';

const customToast = (
  content: React.ReactNode | ((toast: Toast) => React.ReactNode),
  options?: ToastOptions,
  className?: string,
) =>
  toast.custom(
    (toastInstance: Toast) => (
      <div className={className}>
        {typeof content === 'function' ? content(toastInstance) : content}
      </div>
    ),
    options,
  );

const notificationToast = (
  message: string,
  icon: Icons,
  className: string,
  iconClassName: string,
  secondaryMessage?: string,
  options?: ToastOptions,
) =>
  customToast(
    <div
      className={cx(
        className,
        'flex flex-col justify-center space-x-2 rounded-lg border-2 px-3 py-2',
      )}
    >
      <div className="flex items-start justify-center space-x-2 text-xs font-bold">
        <Icon type={icon} size="md" className={iconClassName} />
        <p className="text-xs">{message}</p>
        <Button
          icon="cross"
          type="styleless-icon"
          onClick={() => {
            toast.dismiss(options.id);
          }}
        />
      </div>
      <div className="flex justify-start">
        <p className="ml-8 text-xs text-text-4">{secondaryMessage}</p>
      </div>
    </div>,
    options,
    'flex justify-center',
  );

export default {
  success: (
    message: string,
    secondaryMessage?: string,
    options?: ToastOptions,
  ) =>
    notificationToast(
      message,
      'check-circle',
      'border-border-10 bg-surface-18',
      'fill-icon-16',
      secondaryMessage,
      { ...options, id: uniqueId() },
    ),
  error: (message: string, secondaryMessage?: string, options?: ToastOptions) =>
    notificationToast(
      message,
      'cross-circle',
      'border-border-1 bg-surface-4',
      'fill-icon-17',
      secondaryMessage,
      { ...options, id: uniqueId() },
    ),
  warning: (
    message: string,
    secondaryMessage?: string,
    options?: ToastOptions,
  ) =>
    notificationToast(
      message,
      'exclamation-mark-circle',
      'border-border-12 bg-surface-14',
      'fill-icon-18',
      secondaryMessage,
      { ...options, id: uniqueId() },
    ),
  information: (
    message: string,
    secondaryMessage?: string,
    options?: ToastOptions,
  ) =>
    notificationToast(
      message,
      'information-circle',
      'border-border-6 bg-surface-8',
      'fill-icon-19',
      secondaryMessage,
      { ...options, id: uniqueId() },
    ),
  loading: toast.loading,
};
