import { useMergeRefs, FloatingPortal } from '@floating-ui/react';
import { useTooltipContext } from 'hooks/tooltip/use-tooltip-context';
import { forwardRef } from 'react';

export const TooltipContent = forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, ...props }, propRef) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!context.open) return null;

  return (
    <FloatingPortal>
      <div
        ref={ref}
        className="z-50 max-w-xl rounded-md bg-surface-9 p-1.5 font-semibold text-white"
        style={{
          ...context.floatingStyles,
          ...style,
        }}
        {...context.getFloatingProps(props)}
      />
    </FloatingPortal>
  );
});
