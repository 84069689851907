import cx from 'clsx';
import Icon from 'components/icon';
import Spinner from 'components/spinner';
import { LegacyRef, forwardRef } from 'react';
import {
  ButtonTypes,
  CommonButtonProps,
} from 'v2.api/src/common-generic/types/button';

interface ButtonProps extends CommonButtonProps<React.ReactNode> {
  htmlType?: 'button' | 'submit' | 'reset';
  onClick?: (arg?: React.MouseEvent) => void;
  isLoading?: boolean;
  formId?: string;
  id?: string;
  type?: ButtonTypes;
}

export const getStyles = (type: ButtonTypes, isDisabled: boolean) => {
  const baseStyle =
    'flex items-center justify-center space-x-2 focus:outline-none focus:ring-2 transition duration-100 ease select-none text-xs font-bold';
  const filledStyle = `${baseStyle} px-3 py-2 h-8 focus:ring-offset-2`;

  switch (type) {
    case 'classic':
      return cx(
        filledStyle,
        'text-white focus:ring-ring-1 rounded-lg border border-transparent bg-button-1 disabled:bg-button-2',
        { 'shadow hover:shadow-01': !isDisabled },
      );
    case 'outline':
      return cx(
        filledStyle,
        'border-1.5 border-border-3 rounded-lg text-text-3 focus:ring-ring-1 disabled:text-text-2',
        { 'shadow hover:shadow-01 hover:bg-surface-17': !isDisabled },
      );
    case 'styleless':
      return cx(
        baseStyle,
        'px-3 py-2 hover:text-text-1 rounded-lg focus:text-text-1 focus:ring-ring-1 disabled:text-text-2',
      );
    case 'danger':
      return cx(
        filledStyle,
        'px-3 py-2 bg-button-4 border-transparent text-text-5 rounded-lg focus:ring-ring-1 disabled:bg-button-3',
        { 'shadow hover:shadow-01': !isDisabled },
      );
    case 'success':
      return cx(
        filledStyle,
        'px-3 py-2 bg-button-5 border-transparent text-text-5 rounded-lg focus:ring-ring-1 disabled:bg-button-3',
        { 'shadow hover:shadow-01': !isDisabled },
      );
    case 'classic-icon':
      return cx(
        filledStyle,
        'w-8 text-text-5 bg-button-1 rounded-lg focus:ring-ring-1 disabled:bg-button-2',
        { 'shadow hover:shadow-01': !isDisabled },
      );
    case 'outline-icon':
      return cx(
        filledStyle,
        'w-8 text-text-3 border-1.5 border-border-3 rounded-lg focus:ring-ring-1 disabled:text-text-2',
        { 'hover:bg-button-3 focus:bg-button-3': !isDisabled },
      );
    case 'styleless-icon':
      return cx(
        baseStyle,
        'w-4 h-4 hover:text-text-1 rounded focus:ring-ring-1 focus:text-text-1 disabled:text-text-2',
      );
    case 'danger-icon':
      return cx(
        filledStyle,
        'w-8 text-text-5 bg-button-4 rounded-lg focus:ring-ring-1 disabled:bg-button-6',
        { 'shadow hover:shadow-01': !isDisabled },
      );
  }
};

const Button = forwardRef(function Button(
  {
    type = 'classic',
    htmlType = 'button',
    icon,
    iconSize = 'sm',
    className,
    iconClassName,
    onClick,
    isDisabled,
    isLoading,
    children,
    title,
    formId,
    'data-testid': dataTestId,
    id,
  }: ButtonProps,
  forwardedRef: LegacyRef<HTMLButtonElement>,
) {
  return (
    <button
      ref={forwardedRef}
      title={title}
      type={htmlType}
      className={cx(
        { 'cursor-not-allowed': isDisabled || isLoading },
        getStyles(type, isDisabled || isLoading),
        className,
      )}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      form={formId}
      data-testid={dataTestId}
      id={id}
    >
      {icon && !isLoading && (
        <Icon
          className={cx('fill-current', iconClassName)}
          type={icon}
          size={iconSize}
        />
      )}
      {isLoading && <Spinner />}
      {typeof children === 'string' ? <p>{children}</p> : children}
    </button>
  );
});

export default Button;
