import cx from 'clsx';
import {
  FocusEventHandler,
  InputHTMLAttributes,
  MutableRefObject,
} from 'react';
import { InputStyleTypes } from 'types/input';

import InputError from './input-error';

interface Props
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'prefix' | 'suffix' | 'onChange'
  > {
  fieldId: string;
  error?: React.ReactNode;
  label?: React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  placeholder?: string;
  isDisabled?: boolean;
  children: JSX.Element;
  inputRef?: MutableRefObject<HTMLDivElement>;
  wrapperRef?: MutableRefObject<HTMLDivElement>;
  onMouseEnter?: () => void;
  inputWrapperType?: InputStyleTypes;
  inputWrapperClassName?: string;
  inputId?: string;
  shouldSkipBasicStyle?: boolean;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  shouldDisplayAsterisk?: boolean;
}

const InputWrapper = ({
  fieldId,
  error,
  label,
  prefix,
  suffix,
  children,
  inputRef,
  isDisabled,
  onFocus,
  onBlur,
  onMouseEnter,
  className,
  wrapperRef,
  inputWrapperType,
  inputWrapperClassName,
  shouldSkipBasicStyle = false,
  shouldDisplayAsterisk = false,
}: Props) => {
  const flex = 'flex items-center justify-between space-x-2';

  return (
    <div
      id={fieldId}
      onMouseEnter={onMouseEnter}
      onFocus={onFocus}
      onBlur={onBlur}
      className={cx(inputWrapperClassName, 'space-y-3')}
      ref={wrapperRef}
    >
      {label && (
        <label className="space-x-1 font-semibold">
          <span>{label}</span>
          {shouldDisplayAsterisk && <span className="text-text-7">{'*'}</span>}
        </label>
      )}

      <div
        className={cx(
          {
            'border-border-7': !!error,
            'bg-surface-1': isDisabled,
            'bg-surface-2 shadow':
              !isDisabled && inputWrapperType !== 'styleless',
            'border border-border-3 px-2.5': inputWrapperType !== 'styleless',
            'hover:border-border-5 active:border-border-5':
              inputWrapperType !== 'styleless' && !isDisabled,
            [flex]: inputWrapperType !== 'rich-text-area',
            'w-full': !className,
            'h-10': !className && inputWrapperType !== 'filter',
            'h-8': inputWrapperType === 'filter',
            'appearance-none rounded-lg text-text-3': !shouldSkipBasicStyle,
          },
          className,
        )}
        ref={inputRef}
      >
        {prefix && <div>{prefix}</div>}
        {children}
        {suffix && <div>{suffix}</div>}
      </div>
      <InputError>{error}</InputError>
    </div>
  );
};

export default InputWrapper;
