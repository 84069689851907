import Icon from './icon';

interface Props {
  children: React.ReactNode;
}

const InputError = ({ children }: Props) => {
  if (!children) return null;

  return (
    <div className="mt-2 flex items-center">
      <Icon type="exclamation-mark-circle" className="fill-icon-3" />
      <p className="ml-1 font-medium text-text-7">{children}</p>
    </div>
  );
};

export default InputError;
