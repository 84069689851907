import Button from 'components/button';
import Modal, { Props as ModalProps } from 'components/modal';
import { createRoot } from 'react-dom/client';
import { Icons } from 'v2.api/src/common-generic';
import { ButtonTypes } from 'v2.api/src/common-generic/types/button';

export interface ConfirmationModalProps extends ModalProps {
  okText?: string;
  okIcon?: Icons;
  isOkButtonDisabled?: boolean;
  cancelText?: string;
  okTextType?: ButtonTypes;
  onSubmit?: () => Promise<void> | void;
  onCustomCancel?: () => Promise<void> | void;
  formId?: string;
  isLoading?: boolean;
  hasIcon?: boolean;
  contentClassName?: string;
}

type Config = Omit<ConfirmationModalProps, 'isVisible'>;

const ConfirmationModal = ({
  okText = 'Confirmer',
  okIcon,
  isOkButtonDisabled = false,
  cancelText,
  okTextType = 'classic',
  onSubmit,
  onCustomCancel,
  formId,
  contentClassName = 'sm:max-w-lg',
  ...restProps
}: ConfirmationModalProps): React.ReactNode => {
  const renderFooter = (
    onAction: (onAction: () => Promise<void> | void) => () => Promise<void>,
    onClose: () => void,
    isLoading: boolean,
  ) => (
    <div className="flex flex-row-reverse justify-between bg-surface-1 py-3 sm:px-6">
      <Button
        type={okTextType}
        onClick={onAction(onSubmit)}
        isDisabled={isOkButtonDisabled}
        isLoading={isLoading}
        className="space-x-2"
        icon={okIcon}
        iconSize="sm"
        formId={formId}
        htmlType={formId ? 'submit' : 'button'}
      >
        {okText}
      </Button>
      {cancelText && (
        <Button
          type="outline"
          onClick={() => {
            onCustomCancel?.();
            onClose?.();
          }}
        >
          {cancelText}
        </Button>
      )}
    </div>
  );

  return (
    <Modal
      {...restProps}
      contentClassName={contentClassName}
      renderFooter={restProps.renderFooter || renderFooter}
    />
  );
};

export const confirm = (
  config: Config,
): { destroy: () => void; update: (newConfig: Config) => void } => {
  const container = document.createElement('div');
  const root = createRoot(container);

  const render = ({
    okTextType = 'classic',
    hasIcon = true,
    ...props
  }: ConfirmationModalProps) => {
    root.render(
      <ConfirmationModal
        {...props}
        okTextType={okTextType}
        iconType={hasIcon ? 'warning' : null}
      />,
    );
  };

  const destroy = () => {
    root.unmount();

    if (container.parentNode) {
      container.parentNode.removeChild(container);
    }
  };

  const update = (newConfig: Config) => {
    currentConfig = {
      ...currentConfig,
      ...newConfig,
    };
    render(currentConfig);
  };

  let currentConfig = {
    ...config,
    destroy,
    isVisible: true,
  };

  render(currentConfig);

  return { destroy, update };
};

export default ConfirmationModal;
