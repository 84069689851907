import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cx from 'clsx';
import Icon from 'components/icon';
import { useState } from 'react';
import { SelectValue } from 'v2.api/src/common-generic/types/select';

interface Props<T> {
  value?: SelectValue<T, React.ReactNode>;
  selectedValue: SelectValue<T, React.ReactNode>;
  positionOffset?: Parameters<typeof offset>[0];
  rowClassName?: string;
}

function SubMenu<T>({
  value,
  selectedValue,
  positionOffset,
  rowClassName,
}: Props<T>) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const { x, y, refs, strategy, context } = useFloating({
    open: isDropdownVisible,
    onOpenChange: setIsDropdownVisible,
    middleware: [
      offset(positionOffset || { mainAxis: -194, crossAxis: 290 }),
      flip(),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });
  const { getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context),
  ]);

  return (
    <div
      ref={refs.setReference}
      onClick={() => {
        setIsDropdownVisible(true);
      }}
      className={cx(
        'flex cursor-pointer items-center justify-between rounded-full px-2 py-1.5 font-bold text-text-2 hover:bg-hover-2',
        {
          'bg-surface-8 text-text-1': selectedValue?.id === value.id,
        },
        rowClassName,
      )}
    >
      {value.label}
      <Icon
        type="arrow"
        size="xs"
        className={cx('fill-icon-1', {
          'rotate-90': isDropdownVisible,
          '-rotate-90': !isDropdownVisible,
        })}
      />
      {isDropdownVisible && (
        <FloatingFocusManager
          context={context}
          modal={false}
          order={['reference', 'content']}
          returnFocus={false}
        >
          <div
            {...getFloatingProps({
              className: 'Popover',
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              },
            })}
          >
            {value.renderSubMenu()}
          </div>
        </FloatingFocusManager>
      )}
    </div>
  );
}

export default SubMenu;
