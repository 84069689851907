import {
  Placement,
  useFloating,
  offset,
  shift,
  autoUpdate,
  useInteractions,
  useClick,
  useRole,
  flip,
  useDismiss,
  FloatingPortal,
} from '@floating-ui/react';
import cx from 'clsx';
import Icon from 'components/icon';
import Spinner from 'components/spinner';
import Fuse from 'fuse.js';
import useIsMobile from 'hooks/use-is-mobile';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  SelectValue,
  SelectValues,
} from 'v2.api/src/common-generic/types/select';

import SubMenu from './sub-menu';

interface Props<T> {
  values: SelectValues<T, React.ReactNode>;
  isDisabled?: boolean;
  isSearchEnabled?: boolean;
  isLoading?: boolean;
  selectedValues?: SelectValues<T, React.ReactNode>;
  className?: string;
  triggerClassName?: string;
  emptySearchMessage?: string;
  onChange: (selectedValue: SelectValue<T, React.ReactNode>) => void;
  onChangeDropdownVisibility: (isVisible: boolean) => void;
  renderAfterDropdown?: () => React.ReactNode;
  renderTrigger: () => React.ReactNode;
  dropdownWidth?: number;
  isDropdownVisible: boolean;
  placement?: Placement;
  resetLabel?: string;
  isMultiple: boolean;
  isApiSearchEnabled?: boolean;
  handleAsyncSearch?: (search?: string) => Promise<void>;
  onMouseEnter?: () => void;
  rowClassName?: string;
  wrapperClassName?: string;
}

function Dropdown<T>({
  values,
  isDisabled = false,
  isDropdownVisible,
  isSearchEnabled = false,
  isLoading = false,
  selectedValues,
  className,
  emptySearchMessage,
  onChange,
  onChangeDropdownVisibility,
  renderTrigger,
  dropdownWidth,
  triggerClassName,
  placement,
  resetLabel,
  isMultiple,
  handleAsyncSearch,
  isApiSearchEnabled,
  onMouseEnter,
  rowClassName,
  wrapperClassName,
}: Props<T>) {
  const containerRef = useRef(null);
  const [value, setValue] = useState('');
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const isMobile = useIsMobile();

  const { x, y, refs, strategy, context } = useFloating({
    open: isDropdownVisible,
    onOpenChange: onChangeDropdownVisibility,
    middleware: [offset(5), flip(), shift()],
    placement,
    whileElementsMounted: autoUpdate,
  });

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context),
  ]);

  const [initialValues, setInitialValues] = useState<
    SelectValues<T, React.ReactNode>
  >([]);

  useEffect(() => {
    if (isEmpty(values) || !isApiSearchEnabled) return;

    setInitialValues(values);
  }, [isApiSearchEnabled, values]);

  useEffect(() => {
    setValue('');
  }, [isDropdownVisible]);

  const handleSelectedValue =
    (selectedValue: SelectValue<T, React.ReactNode>) => () => {
      if (!isMultiple) onChangeDropdownVisibility(false);
      onChange(selectedValue);
      setValue('');
    };

  const getFilteredValues = () => {
    const fuse = new Fuse(values, {
      keys: ['filterValue'],
      threshold: 0.4,
    });

    const result = fuse.search(value);

    return map(result, ({ item }) => item);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounceSearch = useCallback(
    debounce(async (value) => {
      try {
        setIsSearchLoading(true);
        await handleAsyncSearch(value);
      } finally {
        setIsSearchLoading(false);
      }
    }, 500),
    [],
  );

  const renderSearchInput = () => (
    <div className="-m-1 mb-1 flex items-center bg-surface-1 px-3 py-2">
      <Icon type="magnifying-glass" size="sm" className="fill-icon-1" />
      <input
        className="ml-2 w-full bg-transparent font-semibold text-text-3 placeholder-color-1 outline-none"
        placeholder="Filtrer..."
        onChange={({ target: { value } }) => {
          setValue(value);
          isApiSearchEnabled && handleDebounceSearch(value);
        }}
        autoFocus
      />
    </div>
  );

  const renderRow = (value: SelectValue<T, React.ReactNode>) => {
    const isSelected = isMultiple
      ? some(selectedValues, ({ id }) => value.id === id)
      : selectedValues[0]?.id === value.id;

    return (
      <div
        role="button"
        key={`${value.id}`}
        onClick={(evt) => {
          evt.stopPropagation();
          handleSelectedValue(value.value ? value : null)();
        }}
        className={cx(
          'cursor-pointer rounded-full px-2 py-1.5 font-bold text-text-2 hover:bg-hover-2',
          {
            'bg-surface-8 text-text-1': isSelected,
            'pointer-events-none': !isMultiple && isSelected,
          },
          rowClassName,
        )}
      >
        {value.label}
      </div>
    );
  };

  const renderContent = () => {
    if (isLoading || isSearchLoading) {
      return (
        <div className="flex items-center justify-center p-2">
          <Spinner />
        </div>
      );
    }

    if (isEmpty(values)) {
      return (
        <div className="flex items-center justify-center p-2">
          <Icon
            type="exclamation-mark-circle"
            size="sm"
            className="mr-2 fill-icon-5"
          />
          <p className="font-medium text-text-2">
            {'Aucune option disponible'}
          </p>
        </div>
      );
    }

    const filteredValues =
      value && !isApiSearchEnabled
        ? getFilteredValues()
        : !value && isApiSearchEnabled
          ? initialValues
          : values;

    const orderedValues = isMultiple
      ? sortBy(filteredValues, [
          (el) => find(selectedValues, (value) => value.id == el.id),
        ])
      : filteredValues;

    if (isEmpty(orderedValues) && emptySearchMessage) {
      return (
        <div className="flex items-center justify-center p-2">
          <Icon
            type="exclamation-mark-circle"
            size="sm"
            className="mr-2 fill-icon-5"
          />
          <p className="text-text-2">{emptySearchMessage}</p>
        </div>
      );
    }

    return map(orderedValues, (value) => {
      if (value.renderSubMenu) {
        return (
          <SubMenu
            value={value}
            selectedValue={selectedValues[0]}
            key={`${value.id}`}
            positionOffset={value.positionOffset}
            rowClassName={rowClassName}
          />
        );
      }

      return renderRow(value);
    });
  };

  const renderDropdown = () => {
    const style = dropdownWidth ? { width: dropdownWidth } : {};

    return (
      <div
        {...getFloatingProps({
          className: 'z-20',
          ref: refs.setFloating,
          style: {
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          },
        })}
        onMouseEnter={() => {
          onMouseEnter?.();
        }}
      >
        <div className="flex" style={style}>
          <div
            className={cx(
              'max-h-60 w-full space-y-0.5 overflow-auto rounded-2xl bg-surface-2 p-1 shadow',
              className,
            )}
          >
            {resetLabel && renderRow({ id: null, label: resetLabel })}
            {isSearchEnabled && renderSearchInput()}
            {renderContent()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={cx(
        { 'hover:cursor-not-allowed': isDisabled },
        wrapperClassName,
      )}
      ref={containerRef}
    >
      <div
        ref={refs.setReference}
        className={cx({ 'pointer-events-none': isDisabled }, triggerClassName)}
        onClick={(evt) => {
          evt.stopPropagation();
          onChangeDropdownVisibility(!isDropdownVisible);
        }}
      >
        {renderTrigger()}
      </div>
      {isMobile ? (
        <FloatingPortal root={containerRef.current}>
          {isDropdownVisible && renderDropdown()}
        </FloatingPortal>
      ) : (
        <FloatingPortal>{isDropdownVisible && renderDropdown()}</FloatingPortal>
      )}
    </div>
  );
}

export default Dropdown;
