import { Placement } from '@floating-ui/react';
import { ReactElement } from 'react';

import { TooltipContent } from './tooltip-content';
import { TooltipTrigger } from './tooltip-trigger';
import { TooltipWrapper } from './tooltip-wrapper';

export interface MainProps {
  children: ReactElement;
  label: string | JSX.Element;
  isDisabled?: boolean;
  style?: Record<string, string | number>;
  className?: string;
}
export interface TooltipProps {
  placement?: Placement;
  mainAxisOffset?: number;
  crossAxisOffset?: number;
}

const Tooltip = ({
  children,
  label,
  isDisabled,
  className,
  style,
  ...options
}: TooltipProps & MainProps) => {
  if (isDisabled || !label) return children;

  return (
    <TooltipWrapper {...options}>
      <TooltipTrigger className={className} style={style}>
        {children}
      </TooltipTrigger>
      <TooltipContent>{label}</TooltipContent>
    </TooltipWrapper>
  );
};

export default Tooltip;
